import './App.css';
import React from 'react';
import { connect } from 'react-redux';
import Canvas from './canvas';
import NextUp from './next-up';
import {
  getHand, removeTileFromDeck, rotateCCW, rotateCW,
} from './state/reducer/hand';
import {
  getPath, getNext, getScore, gameIsOver,
} from './state/reducer/path';
import Header from './header';
import GameOver from './game-over';

function App({
  sprites, hand, path, next, score, rotateTileCCW, rotateTileCW, playTile, gameOver,
}) {
  return (
    <div className="App">
      <header className="header">
        <Header score={score} />
      </header>
      <section id="playing-board">
        <Canvas sprites={sprites} path={path} next={next} />
      </section>
      <section id="next-tiles-to-place">
        <NextUp
          sprites={sprites}
          hand={hand}
          rotateCCW={rotateTileCCW}
          rotateCW={rotateTileCW}
          playTile={playTile}
        />
      </section>
      { (gameOver) && <GameOver score={score} path={path} /> }
    </div>
  );
}

export default connect((state) => ({
  hand: getHand(state),
  path: getPath(state),
  next: getNext(state),
  score: getScore(state),
  gameOver: gameIsOver(state),
}), ((dispatch) => ({
  rotateTileCCW: (index) => dispatch(rotateCCW(index)),
  rotateTileCW: (index) => dispatch(rotateCW(index)),
  playTile: (index) => dispatch(removeTileFromDeck(index)),
})))(App);
