import React, { useState } from 'react';
import { changeSize, getOtherSize } from '../utils/canvas-drawing';

const Header = ({ score }) => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  return (
    <div className="header-container">
      <div className="element instruction">
        Make a long path
      </div>
      <div className="element score">
        <span>score: </span>
        <span>{ score }</span>
      </div>
      <div>
        <div className="element menu-button" onClick={() => setMenuExpanded(!menuExpanded)}>
          <div className="hamburger" />
          <div className="hamburger" />
          <div className="hamburger" />
        </div>
        { menuExpanded && (
          <div className="menu-container">
            <div className="menu-item" onClick={() => window.location.reload()}>new game</div>
            {/* <div className="menu-item">help</div> */}
            <div className="menu-item" onClick={() => changeSize()}>{`change to ${getOtherSize()}`}</div>
          </div>
        ) }
      </div>
    </div>
  );
};

export default Header;
