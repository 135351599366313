import React, { useEffect, useRef } from 'react';
import { buildLayers } from './utils/build-layers';
import {
  CANVAS_WIDTH, CANVAS_HEIGHT, TILE_SIZE, drawGrid, drawStageTile, highlightCell,
} from './utils/canvas-drawing';
import { keyToCoords } from './utils/transformers';

const Canvas = ({ sprites, path, next }) => {
  const canvas = useRef(null);
  const canvasElement = useRef(null);
  const stage = useRef(null);
  const stageElement = useRef(null);

  useEffect(() => {
    canvas.current = canvasElement.current.getContext('2d');
    canvas.current.imageSmoothingEnabled = false;

    stage.current = stageElement.current.getContext('2d');
    stage.current.imageSmoothingEnabled = false;
  }, []);

  useEffect(() => {
    // const x = 0;
    // const y = 0;
    // const pixX = TILE_SIZE * x;
    // const pixY = TILE_SIZE * y;

    // const tile = generateTile();

    canvas.current.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    drawGrid(canvas.current);

    Object.keys(path).forEach((tileLocation) => {
      const layers = buildLayers(path[tileLocation]);
      const { x, y } = keyToCoords(tileLocation);
      stage.current.clearRect(0, 0, TILE_SIZE, TILE_SIZE);
      layers.forEach((layer) => {
        drawStageTile(stage.current, sprites, layer);
      });
      canvas.current.clearRect(x * TILE_SIZE + 1, y * TILE_SIZE + 1, TILE_SIZE - 1, TILE_SIZE - 1);
      canvas.current.drawImage(stageElement.current, x * TILE_SIZE, y * TILE_SIZE);
    });

    highlightCell(canvas.current, next.x, next.y);
  }, [sprites, path, next]);

  const canvasClickHandler = () => {};
  // const canvasClickHandler = (e) => {
  //   console.log(e.clientX, e.clientY);
  // };

  return (
    <div className="container">
      <canvas style={{ border: '1px solid black', maxWidth: '90%' }} width={CANVAS_WIDTH * TILE_SIZE} height={CANVAS_HEIGHT * TILE_SIZE} ref={canvasElement} onClick={canvasClickHandler} />
      <canvas width={TILE_SIZE} height={TILE_SIZE} ref={stageElement} style={{ border: '1px solid red', display: 'none' }} />
    </div>
  );
};
export default Canvas;
