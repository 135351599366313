import React, { useLayoutEffect, useState } from 'react';
import { getStats, loadStats, saveStat } from '../utils/get-stats';

const GameOver = ({ path }) => {
  const [visible, setVisible] = useState(true);
  const [currentStats, setCurrentStats] = useState(false);
  const [previousStats, setPreviousStats] = useState(false);

  useLayoutEffect(() => {
    setCurrentStats(getStats({ tiles: path }));
    setPreviousStats(loadStats());
    // eslint-disable-next-line no-console
    console.log(saveStat({ tiles: path }));
  }, [path]);

  return visible && (
    <div className="game-over-overlay">
      <header>
        <div className="element close-button" onClick={() => setVisible(false)}>
          <div className="x-up" />
          <div className="x-down" />
        </div>
      </header>

      <h2>GAME OVER</h2>
      {currentStats && (
        <section>
          <table>
            <tbody>
              <tr>
                <td className="your-score-is">Your final score is:</td>
                <td className="score">{currentStats.score}</td>
              </tr>
              <tr>
                <td className="your-score-is">Your path distance is:</td>
                <td className="score">{currentStats.distance}</td>
              </tr>
              <tr>
                <td className="your-score-is">Final tile count is:</td>
                <td className="score">{currentStats.tiles}</td>
              </tr>
            </tbody>
          </table>
        </section>
      )}
      {previousStats && (
        <section>
          <h3 className="previous-scores">Previous Top Ten High Scores</h3>
          <table>
            <thead>
              <tr>
                <th> </th>
                <th>score</th>
                <th>path distance</th>
                <th>tiles used</th>
              </tr>
            </thead>
            <tbody>
              {previousStats.map((stat, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={`stat-${index}`}>
                  <td>{index + 1}</td>
                  <td>{stat.score}</td>
                  <td>{stat.distance}</td>
                  <td>{stat.tiles}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}
    </div>
  );
};

export default GameOver;
